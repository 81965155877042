<template>
  <!-- <MembershipTypes :unitType="type" /> -->
  <MembershipTypes :unitType="''" />
</template>

<script>
import MembershipTypes from "@/components/Admin/MembershipType/MembershipTypes";

export default {
  components: {
    MembershipTypes,
  },
  data() {
    return {
      type: null,
    };
  },
  watch: {
    $route(to) {
      if (to.params.type) {
        this.type = this.getTypeFrom(to.params.type);
      }
    },
  },
  created() {
    if (this.$route.params.type) {
      this.type = this.getTypeFrom(this.$route.params.type);
    }
  },
  methods: {
    getTypeFrom(type) {
      switch (type) {
        case "single-session":
          return "SingleSession";
        case "card":
          return "Card";
        case "unlimited":
          return "Unlimited";
      }
    },
  },
};
</script>